//
//  WEBFORM
//------------------------------------------------------------------------------


.form-item.is-error .form-item__widget,
.form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00!important;
}


.paragraph--contact {
  label {
    color: $white;
    line-height: rem(40);
    font-weight: 400;
  }
  // input[type="radio"] {
  //   display: none;
  //   + label {
  //     text-transform: none;
  //     &:before {
  //       display: inline-block;
  //       content: '';
  //       background: $white;
  //       width: rem(16);
  //       height: rem(16);
  //       margin-right: rem(10);
  //       border-radius: 100%;
  //     }
  //   }
  //   &:checked + label:before {
  //     background: $green;
  //     width: rem(10);
  //     height: rem(10);
  //     border: 3px solid $white;
  //   }
  // }
  input[type="text"],
  input[type="email"],
  textarea {
    background: $grey3;
    width: 100%;
    height: rem(40);
    margin: 0;
    padding: 0 rem(10);
    color: $brown;
    line-height: rem(40);
    border: 1px solid $grey3;
    &:focus {
      outline: none;
      border-color: $brown;
    }
  }
  textarea {
    height: rem(200);
  }
  input[type="submit"] {
    background: transparent;
    height: rem(50);
    margin: rem(20) 0 0;
    padding: 0 rem(30);
    line-height: rem(50);
    color: $brown;
    text-transform: uppercase;
    font-weight: 700;
    border: 1px solid $brown;
    transition: all .3s ease-out;
    &:hover {
      background: $brown;
      color: $white;
    }
  }
  .form-item__required,
  .form-required {
    color: $white;
  }
  .grippie {
    display: none;
  }
}



//***********************
//***** BREAKPOINTS *****
//***********************
@media screen and (max-width: 1199px) {



}

@media screen and (max-width: 999px) {}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 479px) {}




