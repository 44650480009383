//
//  OVERRIDES
//------------------------------------------------------------------------------


// Menu
.nav-menu__item, .menu__item {
  list-style-image: none;
  list-style-type: none;
}

// Messages
.messages {
  ul {
    @include reset-list;
  }
}


// // Forms
// .form-item__required,
// .form-required {
//   color: $grey4;
// }

// .form-item.is-error .form-item__widget,
// .form-item--is-error__widget,
// .form-item.is-error input,
// .form-item.is-error textarea,
// .form-item.is-error select,
// .form-item--is-error input,
// .form-item--is-error textarea,
// .form-item--is-error select,
// .form-item input.error,
// .form-item textarea.error,
// .form-item select.error {
//   background: #fff0f0;
//   // border: 0;
// }
// .messages--error-color, .messages--error, .messages.error, .error {
//   background: #fff0f0;
// }





//***********************
//***** BREAKPOINTS *****
//***********************
@media screen and (max-width: 1199px) {}

@media screen and (max-width: 999px) {}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 479px) {}
