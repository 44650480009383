//
//  STRUCTURE
//------------------------------------------------------------------------------

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 rem(10);
  @extend %clearfix;
  position: relative;
}


// LOGO
.header__logo {
  float: left;
  width: 15%;
  margin: 0;
}


// REGIONS
#header,
#system,
#content,
#footer {
  float: left;
  width: 100%;
}

.wrapper {
  overflow: hidden;
}

#header {
  width: 100%;
  padding: rem(20) 0;
  position: absolute;
  // position: relative;
  z-index: 1;
  .not-front & {
    background: $grey2;
    position: relative;
  }
}

#content {
  margin-top: rem(60);
  // padding-bottom: rem(60);
  .front & {
    margin-top: rem(-120);
  }
  .page-user & {
    float: none;
    @extend .container;
    padding-bottom: rem(60);
    overflow: hidden;
    .container {
      padding: 0;
    }
  }
}

#footer {}






//***********************
//***** BREAKPOINTS *****
//***********************
@media screen and (max-width: 1199px) {}

@media screen and (max-width: 999px) {}

@media screen and (max-width: 767px) {

  .header__logo {
    width: 30%;
  }

}

@media screen and (max-width: 479px) {}
