/*  FUNCTIONS
==============================================================================*/

// This function will calculate the rem value of pixel value input
@function rem($size) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }
  @return $size / $base-font-size * 1rem;
}



/*  MIXINS
==============================================================================*/

// This mixin will return both the px & rem values
// The only reason is to support IE8
// http://caniuse.com/#search=rem
@mixin font-size($size) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }
  font-size: $size;
  font-size: rem($size);
}

@mixin reset-list() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



/*  PLACEHOLDERS (Carefull: these can't be used in media queries)
==============================================================================*/
// Or maybe they can??
// http://thesassway.com/intermediate/understanding-placeholder-selectors

// REMOVE LIST (Use: @extend %remove-list)
%reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

// ELLIPSIS (Use: @extend %ellipsis)
%ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// BREAK-OUT (Use: @extend %break-out)
%break-out {
  margin-left: -100%;
  margin-right: -100%;
  padding-left: 100%;
  padding-right: 100%;
  // margin: 0 -100%;
  // padding: 0 100%;
}
// REMOVE-BREAK-OUT (Use: @extend %remove-break-out)
%remove-break-out {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  // margin: 0;
  // padding: 0;
}

// CLEARFIX
%clearfix {
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}
