//
//  NAVIGATION
//------------------------------------------------------------------------------

// MAIN
.main-menu {
  float: left;
  width: 72.5%;
  margin: rem(20) 0;
  > ul {
    @include reset-list;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 rem(40) 0 0;
      line-height: rem(35);
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        color: $brown;
      }
    }
  }
}


// LANGUAGE
.block-locale {
  float: right;
  width: 12.5%;
  margin: rem(20) 0 0;
  > ul {
    @include reset-list;
    text-align: right;
    li {
      display: inline-block;
      a, span {
        display: block;
        // background: $green-light;
        // color: $green-dark;
        width: rem(20);
        height: rem(40);
        font-size: rem(14);
        line-height: rem(40);
        text-align: center;
        text-transform: uppercase;
      }
      a {
        color: $white;
        text-decoration: none;
        &:hover {
          color: $brown;
          // background: $orange;
          // color: $white;
        }
        &.active {
          // background: rgba($white,0.2);
          color: $brown;
          font-weight: 700;
          // background: $green;
          // color: $white;
        }
      }
      span {
        text-decoration: line-through;
      }
    }
  }
}


// FOOTER
.footer-menu {
  float: left;
  width: 100%;
  margin: rem(20) 0;
  > ul {
    @include reset-list;
    text-align: center;
    li {
      display: inline;
      // color: $blue;
      font-size: rem(14);
      line-height: rem(40);
      &:after {
        display: inline-block;
        content: '|';
        margin: 0 rem(10);
      }
      &.last:after {
        display: none;
      }
      a {
        color: inherit;
        &:hover {
          // color: $orange;
        }
      }
    }
  }
}


// MOBILE
.mobile-toggle {
  display: none;
  // background: $green;
  float: right;
  margin: rem(5) 0;
  cursor: pointer;
  &:after {
    display: block;
    content: '\f0c9';
    width: rem(40);
    height: rem(40);
    line-height: rem(40);
    font-family: $fa;
    color: $white;
    text-align: center;
  }
  &:hover {
    // background: $orange;
  }
}




//***********************
//***** BREAKPOINTS *****
//***********************
@media screen and (max-width: 1199px) {}

@media screen and (max-width: 999px) {
}

@media screen and (max-width: 767px) {

  .mobile-toggle {
    display: block;
  }

  .mobile {
    display: none;
    background: rgba($black,0.9);
    float: left;
    width: 100%;
    padding: rem(30) 0;
    border-bottom: 1px solid $brown;
  }

  .block-locale {
    float: left;
    width: 100%;
    margin: 0 0 rem(30);
    > ul {
      text-align: center;
    }
  }

  .main-menu {
    float: left;
    width: 100%;
    margin: 0;
    > ul {
      li {
        display: block;
        width: 100%;
        margin: 0;
        font-size: rem(18);
        text-align: center;
      }
    }
  }

}

@media screen and (max-width: 479px) {}

