// FONTS
// @import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Open+Sans:400,700');
$opensans: 'Open Sans', sans-serif;
$amatic: 'Amatic SC', sans-serif;
$fa: 'FontAwesome';

$base-font-family: $opensans;
$base-font-size: 10px;

// COLORS
$white: #ffffff;
$black: #000000;

$brown: #FECC83;

$blue: #647c86;
$green: #3ab394;
$green-light: #ebf7f4;
$green-dark: #0c554b;
$orange: #ff6100;

$grey: #858383;
$grey1: #2a2a2a; // contact bg left
$grey2: #3e3e3e; // contact bg right
$grey3: #7f7f7f; // contact input field bg

$body-color: $grey;
