//
//  ADMIN
//------------------------------------------------------------------------------


// TABS
.tabs__tab {
  background: none;
  border: 0;
  border-radius: 0!important;
}
ul.tabs {
  background: none!important;
  float: left;
  width: 100%;
  margin: rem(30) 0;
  padding: 0;
  border: 0;
  line-height: inherit;
  > li {
    float: left;
    margin: 0 1px 1px 0;
  }
  a {
    background: $white;
    color: $black;
    padding: 0 rem(20);
    line-height: rem(40);
    &.is-active {
      background: $brown;
      color: $white;
    }
  }
}


// MESSAGES
.messages {
  float: left;
  width: 100%;
}


// CONTEXTUAL LINKS
.contextual-links-wrapper a {
  transition: none;
}


// THROBBER
.progress-throbber, .ajax-progress {
  display: none;
}





//***********************
//***** BREAKPOINTS *****
//***********************
@media screen and (max-width: 1199px) {}

@media screen and (max-width: 999px) {}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 479px) {}
