html {
  font-size: $base-font-size; // This is set in the _varibales.scss
}

body {
  margin: 0;
  padding: 0;
  font-family: $base-font-family;
  font-size: rem(14);
  line-height: rem(24);
  color: $grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

h1, h2, h3 {
  margin: 0;
  font-weight: 700;
  font-family: $amatic;
  a {
    color: inherit;
    text-decoration: none;
  }
}
h1 {
  margin: 0 0 rem(30);
  font-size: rem(80);
  line-height: rem(90);
  .not-front & {
    font-size: rem(60);
    line-height: rem(70);
    color: $black;
  }
}
h2 {
  margin-bottom: rem(14);
  font-size: rem(40);
  line-height: rem(50);
  font-weight: 700;
  color: $black;
}
h3 {
  margin-bottom: rem(14);
  font-size: rem(14);
  line-height: rem(24);
  font-family: $opensans;
  font-weight: 700;
  color: $black;
}

.bigTitle {
  font-size: rem(40);
  line-height: rem(46);
}

.smallTitle {
  font-size: rem(27);
  line-height: rem(33);
}

.lineCentered {
  &:after {
    display: block;
    content: '';
    width: rem(35);
    height: rem(5);
    margin: rem(20) auto 0;
  }
}
.lineLeft {
  &:after {
    display: block;
    content: '';
    width: rem(35);
    height: rem(5);
    margin: rem(20) 0 0;

  }
}

a {
  color: $brown;
  text-decoration: underline;
  transition: all .2s ease-out;
  &:hover {
    color: $black;
  }
}

p {
  margin: 0 0 rem(28);
}

ul {
  margin-top: 0;
  margin-bottom: rem(28);
}

pre {
  font-size: rem(12);
  line-height: rem(16);
}

strong, b {
  font-weight: 700;
}






//***********************
//***** BREAKPOINTS *****
//***********************
@media screen and (max-width: 1199px) {}

@media screen and (max-width: 999px) {}

@media screen and (max-width: 767px) {

  h1 {
    font-size: rem(50);
    line-height: rem(60);
  }

}

@media screen and (max-width: 479px) {}

