//
//  PARAGRAPHS
//------------------------------------------------------------------------------

.paragraphs {
  float: left;
  width: 100%;
}
.paragraph {
  float: left;
  width: 100%;
  margin-bottom: rem(60);
}


// BANNER
.paragraph--banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: rem(500) 0 rem(250);
  position: relative;
  &:after {
    display: block;
    content: '';
    background-image: url(../images/banner-wave.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    width: 100%;
    height: rem(350);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
  .scroll-down {
    background: url(../images/scroll-down-white.png) no-repeat center center;
    width: rem(20);
    height: rem(50);
    margin-left: rem(-10);
    cursor: pointer;
    position: absolute;
    bottom: rem(75);
    left: 50%;
    &:hover {
      background: url(../images/scroll-down-brown.png) no-repeat center center;
    }
  }
}
.banner--content {
  color: $white;
  text-align: center;
  position: relative;
  // &:after {
  //   display: block;
  //   // content: url(../images/logo-bio.png);
  //   content: url(../images/bio2.png);
  //   position: absolute;
  //   left: 0;
  //   bottom: rem(-130);
  // }
}
.banner--subtitle {
  margin: 0;
  color: $brown;
  text-transform: uppercase;
}
.banner--text {
  padding: 0 30%;
}


// STORY
.paragraph--story {}
.story--title {
  margin: 0 0 rem(30);
  text-align: center;
}
.story--intro {
  background: rgba($brown,0.2);
  margin: 0 0 rem(120);
  padding: rem(90) 30% rem(60);
  position: relative;
  &:before,
  &:after {
    position: absolute;
  }
  &:before {
    content: url(../images/brood-1.png);
    top: rem(-100);
    right: rem(-50);
  }
  &:after {
    content: url(../images/brood-2.png);
    bottom: rem(-100);
    left: rem(-50);
  }
}


// TEXTIMAGE
.paragraph--textimage {
  &.img-right {
    .textimage--image {
      float: right;
    }
    .textimage--text {
      float: left;
    }
  }
}
.textimage--text {
  float: right;
  width: 45%;
}
.textimage--image {
  float: left;
  width: 45%;
}


// PRODUCTS
.paragraph--products {
  padding-top: rem(90);
  border-top: 2px dashed rgba($grey3,0.2);
}
.products--title {
  margin: 0 0 rem(30);
  text-align: center;
}
.products {}
.product {
  float: left;
  width: 30%;
  margin-right: 5%;
  &:nth-child(1) {
    text-align: right;
  }
  &:nth-child(2) {
    text-align: center;
  }
  &:nth-child(3) {
    margin-right: 0;
    text-align: left;
  }
}
.product--icon {
  height: rem(60);
  line-height: rem(60);
}


// CONTACT
.paragraph--contact {}
.contact--left {
  background: $grey1;
  float: left;
  width: 50%;
}
.contact--right {
  background: $grey2;
  float: right;
  width: 50%;
}
.contact--title {
  color: $white;
}
.contact--form {
  float: right;
  width: 600px;
  padding: rem(90) 20% rem(90) 0;
  color: $white;
}
.contact--text {
  float: left;
  width: 600px;
  color: $white;
  a {
    color: $white;
  }
  p:last-child {
    margin: 0;
  }
}
.contact--text-frame {
  margin: rem(120);
  padding: rem(30);
  border: 1px solid $grey;
}
.contact--map {
  float: left;
  width: 100%;
  > div {
    height: 100%!important;
  }
}




//***********************
//***** BREAKPOINTS *****
//***********************
@media screen and (max-width: 1199px) {

  .paragraph--banner {
    padding: rem(350) 0 rem(250);
  }

  .contact--form,
  .contact--text {
    width: 100%;
    float: left;
    padding: rem(30);
  }
  .contact--text-frame {
    margin: rem(30);
  }

}

@media screen and (max-width: 999px) {

  .paragraph--banner {
    padding: rem(250) 0 rem(200);
  }

  .story--intro {
    &:before {
      top: rem(-100);
      right: rem(-150);
    }
    &:after {
      bottom: rem(-100);
      left: rem(-150);
    }
  }

}

@media screen and (max-width: 767px) {

  .banner--text {
    padding: 0 10%;
  }

  .story--intro {
    padding: rem(90) 20% rem(190);
    &:before {
      display: none;
    }
  }

  .product {
    width: 100%;
    margin: 0 0 rem(60);
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      text-align: left;
    }
  }
  .product--image {
    float: left;
    width: 30%;
  }
  .product--icon,
  .product--title,
  .product--text {
    float: right;
    width: 65%;
  }

  .contact--left,
  .contact--right {
    width: 100%;
  }

  .contact--form {
    padding: rem(60);
  }

}

@media screen and (max-width: 600px) {

  .textimage--image,
  .textimage--text {
    float: left;
    width: 100%;
  }

}

@media screen and (max-width: 479px) {

  .story--intro {
    padding: rem(90) 10% rem(190);
  }

  .product--image,
  .product--icon,
  .product--title,
  .product--text {
    float: left;
    width: 100%;
    text-align: center;
  }

  .contact--form {
    padding: rem(30);
  }

}

